var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _vm.api.isLoading
        ? _c("v-skeleton-loader", { ref: "skeleton", attrs: { type: "table" } })
        : _vm._e(),
      !_vm.api.isLoading && _vm.data != null
        ? _c("v-data-table", {
            staticClass: "elevation-1 mt-4",
            attrs: { items: _vm.data, headers: _vm.dataHeader },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.index",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm._v(" " + _vm._s(_vm.data.indexOf(item) + 1) + " "),
                    ]
                  },
                },
                {
                  key: "item.status",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(item.status == 1 ? "Enable" : "Disabled") +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.beta",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(item.beta == 1 ? "Enable" : "Disabled") +
                            " "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.features",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "PageFeaturesDetail",
                              params: { id: item.id },
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.features) + " ")]
                      ),
                    ]
                  },
                },
              ],
              null,
              false,
              1936537982
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }